'use strict';

const Swiper = require('swiper/swiper-bundle.min.js');

$(function () {
    let inEditor = false;

    // Detect if page is in the editor
    if (window.location === window.parent.location) {
        inEditor = false;
    } else {
        inEditor = true;
    }

    $('.carousel.pd-layout').each(function () {
        let $carousel = this.querySelector('.pd-carousel'); // vanilla js 'this'

        if ($carousel) {
            const $carouselSettings = $(this).find('.carousel-settings');
            const editMode = $carouselSettings.data('editMode');
            const componentId = $carouselSettings.data('componentId');
            const prodCarousel = $carouselSettings.data('prodCarousel');
            const smSlidesToShow = $carouselSettings.data('smSlides');
            const mdSlidesToShow = $carouselSettings.data('mdSlides');
            const lgSlidesToShow = $carouselSettings.data('lgSlides');
            const smCarouselDots = $carouselSettings.data('smDots');
            const mdCarouselDots = $carouselSettings.data('mdDots');
            const lgCarouselDots = $carouselSettings.data('lgDots');
            const smCarouselArrows = $carouselSettings.data('smArrows');
            const mdCarouselArrows = $carouselSettings.data('mdArrows');
            const lgCarouselArrows = $carouselSettings.data('lgArrows');
            const smCarouselScroll = $carouselSettings.data('smScroll');
            const mdCarouselScroll = $carouselSettings.data('mdScroll');
            const lgCarouselScroll = $carouselSettings.data('lgScroll');
            const spaceBetweenSlides = $carouselSettings.data('space-between');
            const showEditMode = inEditor && editMode;
            const spaceBtwnMobile = 24;
            const spaceBtwnTablet = 24;
            const spaceBtwnDesktop = 24;
            const prodSpaceBtwnMobile = 16;
            const prodSpaceBtwnTablet = 24;
            const prodSpaceBtwnDesktop = 48;
            $($carousel).css({
                '--spaceBtwnMobile': `${spaceBtwnMobile}px`,
                '--spaceBtwnTablet': `${spaceBtwnTablet}px`,
                '--spaceBtwnDesktop': `${spaceBtwnDesktop}px`,
                '--prodSpaceBtwnMobile': `${prodSpaceBtwnMobile}px`,
                '--prodSpaceBtwnTablet': `${prodSpaceBtwnTablet}px`,
                '--prodSpaceBtwnDesktop': `${prodSpaceBtwnDesktop}px`
            });

            if (!showEditMode) {
                let smloopCarousel = true;
                let mdloopCarousel = true;
                let lgloopCarousel = true;

                // scrollbar is enabled then carousel loop needs to be disabled.
                if (smCarouselScroll) {
                    smloopCarousel = false;
                }

                if (mdCarouselScroll) {
                    mdloopCarousel = false;
                }

                if (lgCarouselScroll) {
                    lgloopCarousel = false;
                }

                /* eslint no-unused-vars: ["error", { "varsIgnorePattern": "pdCarousel" }]*/
                const pdCarousel = new Swiper($carousel, {
                    direction: 'horizontal',
                    loop: smloopCarousel,
                    slidesPerView: smSlidesToShow,
                    spaceBetween: typeof spaceBetweenSlides !== 'undefined' ? 0 : prodCarousel ? prodSpaceBtwnMobile : spaceBtwnMobile, // eslint-disable-line no-nested-ternary
                    centerInsufficientSlides: true,
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                        enabled: smCarouselDots,
                        clickable: true
                    },
                    navigation: {
                        nextEl: `.swiper-nav-next-${componentId}`,
                        prevEl: `.swiper-nav-prev-${componentId}`,
                        enabled: smCarouselArrows
                    },
                    scrollbar: {
                        el: '.swiper-scrollbar',
                        enabled: smCarouselScroll,
                        draggable: true,
                        hide: false
                    },
                    breakpoints: {
                        544: {
                            spaceBetween: typeof spaceBetweenSlides !== 'undefined' ? 0 : prodCarousel ? prodSpaceBtwnTablet : spaceBtwnTablet // eslint-disable-line no-nested-ternary
                        },
                        769: {
                            loop: mdloopCarousel,
                            slidesPerView: mdSlidesToShow,
                            spaceBetween: typeof spaceBetweenSlides !== 'undefined' ? 0 : prodCarousel ? prodSpaceBtwnTablet : spaceBtwnTablet, // eslint-disable-line no-nested-ternary
                            pagination: {
                                enabled: mdCarouselDots
                            },
                            navigation: {
                                enabled: mdCarouselArrows
                            },
                            scrollbar: {
                                enabled: mdCarouselScroll
                            }
                        },
                        1200: {
                            loop: lgloopCarousel,
                            slidesPerView: lgSlidesToShow,
                            spaceBetween: typeof spaceBetweenSlides !== 'undefined' ? 0 : prodCarousel ? prodSpaceBtwnDesktop : spaceBtwnDesktop, // eslint-disable-line no-nested-ternary
                            pagination: {
                                enabled: lgCarouselDots
                            },
                            navigation: {
                                enabled: lgCarouselArrows
                            },
                            scrollbar: {
                                enabled: lgCarouselScroll
                            }
                        }
                    }
                });
            }
        }
    });
});
